import React, { useEffect, useState } from 'react';
import Input from './Input';
import MessageBox from './MessageBox';
import ChatHeader from '../ChatHeader';
// Replace next-intl with a React i18n library
import { useChat } from 'ai/react';

const ChatDivision = () => {
  const [showTooManyRequestMessage, setShowTooManyRequestMessage] =
    useState(false);

  const { messages, input, handleInputChange, handleSubmit, isLoading } =
    useChat({
      api: 'https://chat-ai.xtechsolution.com.au/api/chat',
      onError: () => {
        setShowTooManyRequestMessage(true);
      },
    });

  useEffect(() => {
    // if (messages?.length > 0 && !isLoading) {
    //   fetch('http://localhost:3002/api/conversation', {
    //     method: 'POST',
    //     body: JSON.stringify(messages),
    //   });
    // }
  }, [isLoading, messages]);

  return (
    <div id="ai_consultant" className="container max-w-full mx-auto">
      <form onSubmit={handleSubmit}>
        <ChatHeader />
        <div className="flex flex-col justify-between bg-[#F7F7F8]">
          <MessageBox messages={messages} />
          {showTooManyRequestMessage && (
            <div className="border border-primary rounded text-primary p-4 m-4 md:mx-12">
              Today's quota for questions has been reached. You can ask again in
              24 hours. If you have a legal issue that needs resolution, we
              recommend contacting a professional customer support immediately!
              <a href="/contact-us" className="ml-1 underline">
                Contact a customer support immediately!
              </a>
            </div>
          )}
          <Input
            input={input}
            handleInputChange={handleInputChange}
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default ChatDivision;
