import React from 'react';

const Header: React.FC<any> = () => {

  return (
    <div className="flex flex-row justify-between items-center py-3 px-4 bg-primary">
      <div>
        <img
          src="/wp-content/uploads/2024/06/chatgpt.svg"
          className="inline"
          width={32}
          height={32}
          alt={'AI Consultant'}
        />
        <span className="pl-4 text-base text-white">{'AI Consultant'}</span>
      </div>
      <span className="text-xs md:text-sm text-white">
        Technical Support:
        <a href="https://xtechsolution.com.au/" className="underline" target='_blank' rel="noreferrer noopener">
          X Technology
        </a>
      </span>
    </div>
  );
};

export default Header;
