import React from 'react';

const Index: React.FC<any> = ({ type, content }) => {
  const isUser = type === 'user';
  const isChatGPT = type === 'assistant' || type === 'default';
  const isDefault = type === 'default';

  const isGenerating = content === '' ? ' generating' : '';

  return (
    <div
      className={
        'flex gap-4 sm:gap-6 p-4 md:py-8 md:px-12 translate-z-0 ' +
        (isUser ? ' bg-white text-right items-center' : ' items-start') +
        (!isDefault ? ' border-t border-t-[#DEDEDF] ai-message' : ' default-message')
      }
    >
      {isChatGPT && (
        <div className="flex flex-initial w-[30px] h-[30px] justify-center items-center bg-primary">
          <img
            src="/wp-content/uploads/2024/06/chatgpt.svg"
            width={21}
            height={21}
            alt="ChatGPT"
          />
        </div>
      )}
      <div
        className={'flex-1 text-black whitespace-pre-line ' + isGenerating}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
      {isUser && (
        <span className="flex justify-center items-center bg-secondary rounded-full text-white w-[30px] h-[30px]">
          Q
        </span>
      )}
    </div>
  );
};

export default Index;
