import React, { useEffect, useRef } from 'react';
import MessageItem from './MessageItem';
import _ from 'lodash';
import { UseChatHelpers } from 'ai/react/dist';

type MessageBoxProps = {
  messages: UseChatHelpers['messages'];
};

const MessageBox: React.FC<MessageBoxProps> = ({ messages }) => {
  const messageRef = useRef<HTMLDivElement>(null);

  const disclaimer = 'Disclaimer:<br />As an AI intelligent assistant for businesses expanding into the Middle East, our responses only represent general viewpoints and information recommendations from the internet, provided for reference and to offer ideas. They do not represent the stance of any company or individual, nor do they constitute legal advice. The intelligent AI assistant cannot guarantee that its answers are completely accurate and applicable to your situation. Final decisions should be based on your specific circumstances and needs, and you must assume the risks and responsibilities of those decisions. Therefore, we strongly recommend that you consult professional institutions for independent policy and regulatory advice. Feel free to contact us anytime, as Casing Consultancies is here to support your business development in the Middle East.';

  const default_msg = 'Hello, I am the AI assistant from Casing Management Consultancies. What questions do you have about expanding into the Middle East?';

  useEffect(() => {
    const message = messageRef.current;
    if (message) {
      message.scrollTo(0, message.scrollHeight);
    }
  }, [messages]);

  return (
    <div className="h-[480px] overflow-y-scroll" ref={messageRef}>
      <MessageItem type="default" content={disclaimer} />
      <MessageItem type="default" content={default_msg} />
      <div className="flex flex-col-reverse">
        {_.reverse([...messages]).map((item, index) => (
          <MessageItem key={index} type={item.role} content={item.content} />
        ))}
      </div>
    </div>
  );
};

export default MessageBox;