import './App.css';
import ChatDivision from "./Components/ChatDivision";

function App() {
  return (
    <div className="App">
      <ChatDivision />
    </div>
  );
}

export default App;
